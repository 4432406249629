import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { CommonService } from 'src/app/services/commonservice.service'
import { DatePipe } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatSelect } from '@angular/material/select';
declare var jQuery: any;
declare var $: any;
 
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  user: any;
  ownerData: any;


  constructor(private datePipe: DatePipe, 
    private activatedRoute: ActivatedRoute, 
    private fb: FormBuilder, 
    public dataservice: CommonService,
    public httpClient: SharedserviceService, 
    public router: Router, 
    private toastr: ToastrManager,
    ) {
    this.user = JSON.parse(localStorage.getItem("userAgency_dtyfd"));
    this.ownerData= JSON.parse(sessionStorage.getItem("ownerData"));
  }

  ngOnInit() {
  }

}

