import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpClientModule, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

var baseUrl = environment.url;

@Injectable({
  providedIn: 'root'
})

export class SharedserviceService {
  public serviceBase: any;
  public serviceBase1: any;
  public baseUrlPartner:any;
  public imageBase: any;
  public webBase: any;
  public imgUrl: any;
  public auth_token: any;
  public loadingShow: boolean = false;
  public progressLoadingShow: boolean = false;
  public progressLoadingValue: number = 0;
  user: any;
  user_type: any;


  // x-api-key demo for office Trip Circuit
  // xkey:any="1RRA1C1644325011794";
  // demoapi for local use
  // xkey:any="1FMQKB1639407126571";

  // demo api for client
  // xkey:any="";

  // x-api-key of economic travels live api
  xkey:any="1JEZU71680610809268";





  constructor(private router: Router, private http: HttpClient) {
    this.serviceBase = baseUrl;
    this.serviceBase1 = baseUrl;
    this.imageBase = '';
    var userdata: any = JSON.parse(localStorage.getItem("userAgency"));
    this.user = JSON.parse(localStorage.getItem("userAgency"));
    this.auth_token=JSON.parse(localStorage.getItem("tokenAgency"));


    // if (userdata != null && this.user != null) {
    //   this.user_type = this.user.role_id;
    //   //
    //console.log('user_type', this.user_type);
    //   if (this.user_type == 3) {
    //     // this.router.navigateByUrl('/');
    //   }
    //   else if (this.user_type == 2) {
    //     // this.router.navigateByUrl('/');
    //   } else {
    //     //this.router.navigateByUrl('');
    //   }
    // }
  }

  get(url: any): Observable<any> {
    if (JSON.parse(localStorage.getItem("tokenAgency"))) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " +JSON.parse(localStorage.getItem("tokenAgency")), 'Content-Type': 'application/json','x-api-key' : this.xkey });
      console.log('header data ::::::::::::: ', headers);

      return this.http.get(this.serviceBase + url, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key' : this.xkey });
      return this.http.get(this.serviceBase + url,  {
        headers: headers
      });
    }
  }
  get1(url: any): Observable<any> {
    var headers: HttpHeaders;
    if (this.auth_token != undefined)
      headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token, 'Content-Type': 'application/json'});
    return this.http.get(this.serviceBase1 + url, {
      headers: headers
    });
  }

  post(url: any, data: any): Observable<any> {
    if (JSON.parse(localStorage.getItem("tokenAgency"))) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " +JSON.parse(localStorage.getItem("tokenAgency")), 'Content-Type': 'application/json','x-api-key' : this.xkey });
      console.log('header data ::::::::::::: ', headers);

      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key' : this.xkey });
      return this.http.post(this.serviceBase + url, data, {
        headers: headers
      });
    }
  }

  patch(url: any, data: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token, 'Content-Type': 'application/json' ,'x-api-key' : this.xkey});
      return this.http.patch(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key' : this.xkey });
      return this.http.patch(this.serviceBase + url, data, {
        headers: headers
      });
    }
  }
  put(url: any, data: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token, 'Content-Type': 'application/json' ,'x-api-key' : this.xkey});
      return this.http.put(this.serviceBase + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key' : this.xkey });
      return this.http.put(this.serviceBase + url, data, {
        headers: headers
      });
    }
  }

  goTo(page: any) {
    this.router.navigate([page]);
  }

  downloadCSV(data: any, fileName: any) {
    var csvData = this.ConvertToCSV(data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }

  ConvertToCSV(objArray: any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  getRequestdata(url: any): Observable<any> {
    let headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token,'x-api-key' : this.xkey});
        headers.append('Content-Type', 'application/json');
        headers.append('language', 'en');
        return this.http.get(this.serviceBase+url, {
        headers: headers,
      });
  }
  getRequest(url: any): Observable<any> {
      if(this.auth_token){
        let headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token,'x-api-key' : this.xkey});
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        headers.append('id', '34');
        return this.http.get(this.serviceBase+url, {
          headers: headers,
        });
      }else{
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        headers.append('x-api-key' , this.xkey);
        headers.append('id', '34');
        return this.http.get(this.serviceBase+url, {
          headers: headers,
        });
      }
  }
  //get request with no key
  getRequest1(url: any): Observable<any> {
      if(this.auth_token){
        let headers = new HttpHeaders({ 'Authorization': "Bearer " +this.auth_token});
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        headers.append('id', '34');
        return this.http.get(this.serviceBase1+url, {
          headers: headers,
        });
      }else{
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        headers.append('id', '34');
        return this.http.get(this.serviceBase1+url, {
          headers: headers,
        });
      }
  }
  // postRequest(url, data){
  postRequest(url:any, data:any): Observable<any> {
    var token = JSON.parse(localStorage.getItem("tokenAgency"));
  if(token){
    let headers = new HttpHeaders({ 'Authorization': "Bearer " +token,'x-api-key' : this.xkey});
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    // headers.append('x-api-key' , this.xkey);
    headers.append('x-api-key' , this.xkey);
    return this.http.post(this.serviceBase+url, data, { headers: headers});
  }else{
    let headers = new HttpHeaders({'x-api-key':this.xkey});
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('x-api-key' , this.xkey);
    return this.http.post(this.serviceBase+url, data, { headers: headers});
  }

  }
  getRequestnotoken(url: any): Observable<any> {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        headers.append('x-api-key' , this.xkey);
        headers.append('id', '34');
        return this.http.get(this.serviceBase+url, {
          headers: headers,
        });
  }
  postRequestnotoken(url: any, data: any): Observable<any>{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('x-api-key' , this.xkey);
    return this.http.post(this.serviceBase+url, data, { headers: headers});
  }



}
