import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CommonService, SharedserviceService } from 'src/app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // showLeftBar:boolean=false;
  // user_id:any;
  // role_id:any;
  // user:any;

  // constructor(private router:Router) { 
  //   this.user = JSON.parse(localStorage.getItem("userAgency"));
  //   
//console.log("user ::::::ASD:::::",this.user)
  //   if(this.user != null){ 
  //     this.user_id = this.user.id;
  //     this.role_id = this.user.role_id;
  //   }
  // }
  

  // ngOnInit() {
  // }
  // logout() {
  //   localStorage.removeItem("tokenAgency");
  //   localStorage.removeItem("user");
  //   this.router.navigateByUrl('/')
  // }


  user:any;
  user_type:any;
  name:any;
  id:any;
  showlogin:boolean=true;
  parseuser:any;
  routing: any;
  getData: any;
  constructor(private router: Router,public commonService:CommonService,public sharedService: SharedserviceService,private toastr: ToastrManager,) {
    
    this.routing=router.url;
  }

  ngOnInit() { 
    // console.log("check current router page",this.router.url);
    // this.contactDetail();
    
    this.getData=JSON.parse(localStorage.getItem("ownerData"));
    this.user=JSON.parse(localStorage.getItem("userAgency"));
    if(this.user){
      this.id = this.user.id;
      this.user_type = this.user.role_id;
      
//console.log("usertype",this.user_type);
      this.showlogin=false;
      this.name = this.user.name;
    }
    
//console.log("user name  ::::",this.name)
  }


  profile(id){
    this.router.navigateByUrl("/dashboard/viewprofile");
   
  }
  logout() {
    this.showlogin=true;
    this.commonService.onLogout();
  }

}
